import { Injectable } from '@angular/core';

import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import { ApiService } from './api.service';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';

@Injectable()
export class DataResolver implements Resolve<any> {
  constructor(private apiservice: ApiService) {}

  resolve(route: ActivatedRouteSnapshot) {
    //return Observable.of('Hello Alligator!').delay(2000);
    return this.apiservice.getbyid('qz', route.paramMap.get('id')).catch(
      () => {  return Observable.of('data not available at this time');

      }
    )
    
  }
}


    