import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



// *******************************************************************************
// NgBootstrap

import { NgbModule  } from '@ng-bootstrap/ng-bootstrap';
// *******************************************************************************
// App

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { LayoutModule } from './layout/layout.module';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { JwtModule } from '@auth0/angular-jwt';



// *******************************************************************************
// Pages

import { HomeComponent } from './home/home.component';
import { Page2Component } from './page-2/page-2.component';
import {AuthenticationLoginV3Component} from './login/authentication-login-v3.component'





// *******************************************************************************
//service 
import { HttpClientModule } from '@angular/common/http';
import { ApiService } from './service/api.service';
import { AuthService } from './service/auth.service';

import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { AuthGuard } from './service/auth.guard';
import { DataResolver } from './service/data.resolver.service';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}


@NgModule({
  declarations: [
    AppComponent,
    // Pages
    AuthenticationLoginV3Component,
    HomeComponent,
    Page2Component,
  ],

  imports: [
    CommonModule,
    BrowserModule,
   
    FormsModule,
    NgbModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger' 
    }),
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutModule,
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      confirmButtonClass: 'btn btn-lg btn-primary',
      cancelButtonClass: 'btn btn-lg btn-default'
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      //  whitelistedDomains: ['localhost:1337'],
      //  blacklistedRoutes: ['localhost:1337/login'],
         whitelistedDomains: ['app.beyanbook.net'],
         blacklistedRoutes: ['app.beyanbook.net/login'],
        skipWhenExpired: true,
      }
    })
   
   
  ],

  providers: [
    Title,
    AppService,
    ApiService,AuthService,AuthGuard,DataResolver
    
  ],
    

  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
