import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// *******************************************************************************
// Layouts
import { Layout1Component } from './layout/layout-1/layout-1.component';
import { LayoutBlankComponent } from './layout/layout-blank/layout-blank.component';
import {AuthenticationLoginV3Component} from './login/authentication-login-v3.component'




// *******************************************************************************
// Pages
import { HomeComponent } from './home/home.component';
// *******************************************************************************
// Routes
import { AuthGuard } from './service/auth.guard';
// *******************************************************************************

const routes: Routes = [

  {
    path: '', component: Layout1Component,canActivate: [AuthGuard], pathMatch: 'full', children: [
      { path: '', component: HomeComponent },
         ]
  },
  //{ path: 'login/:id', redirectTo: 'sauthentication/login/:id', pathMatch: 'full' },
 // { path: 'login', redirectTo: 'sauthentication/login', pathMatch: 'full' },
 {
  path: 'login', component: LayoutBlankComponent, pathMatch: 'full', children: [
    { path: '', component: AuthenticationLoginV3Component },
       ]
},
  { path: 'administration', component: Layout1Component ,canActivate: [AuthGuard], loadChildren: './administration/administration.module#AdministrationModule' },
  { path: 'workplace', component: Layout1Component,canActivate: [AuthGuard], loadChildren: './workplace/workplace.module#WorkplaceModule' },
  { path: 'students', component: Layout1Component,canActivate: [AuthGuard], loadChildren: './students/students.module#StudentsModule' },
  { path: 'sworkplace', component: LayoutBlankComponent,canActivate: [AuthGuard], loadChildren: './sworkplace/sworkplace.module#SworkplaceModule' },
  { path: 'sauthentication', component: LayoutBlankComponent,canActivate: [AuthGuard], loadChildren: './sworkplace/authentication/authentication.module#AuthenticationModule' }
];
// *******************************************************************************
//

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
