import { Component ,OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { Router } from "@angular/router";
import { AppService } from '../app.service';
import { longStackSupport } from 'q';



@Component({
  selector: 'app-authentication-login-v3',
  templateUrl: './authentication-login-v3.component.html',
  styleUrls: [
    '../../vendor/styles/pages/authentication.scss'],

})
export class AuthenticationLoginV3Component {
  restoken: any
  constructor(

    private authservice: AuthService,
    private router: Router,
    private appService: AppService
  ) {
    this.appService.pageTitle = 'Login v3 - Pages';

  }
  alerts: Array<IAlert> = [];
  id: number;
  type: string;
  message: string;

  userData: any
  token: string
  error: any
  credentials = {
    username: '',
    password: '',

  };
  ngOnInit() {
    this.logout();
    
  }
  onSubmit() {

    this.authservice.login('login', this.credentials)
      .subscribe(res => {
        this.restoken = res;
        localStorage.setItem('access_token', this.restoken.token);
        this.router.navigateByUrl('');
      }
        , error => {
          console.log(error)
          this.alerts.push({
            id: 1,
            type: 'danger',
            message: 'يرجى التأكد من اسم المستخدم وكلمة المرور'
          }),
            setTimeout(() => { this.alerts = []; }, 3000);
        })
  }
  public closeAlert(alert: IAlert) {
    const index: number = this.alerts.indexOf(alert);
    this.alerts.splice(index, 1);
  }
  logout(){
    localStorage.removeItem('access_token')
  }
}
export interface IAlert {
  id: number;
  type: string;
  message: string;
}


