import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';



@Injectable()
export class AuthService {
  public token: string;
  public apiurl() {
    let apiurl = "https://app.beyanbook.net"
  //  let apiurl = "http://localhost:1337"
    return apiurl
  }
  constructor(private http: HttpClient) {
  }

  login(subyurl, credentials) {
    return this.http.post(this.apiurl() + '/' + subyurl, credentials)
  }
  logout(): void {
    this.token = null;
    localStorage.clear();
  }


}

