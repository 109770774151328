import { Component, Input, HostBinding } from '@angular/core';
import { AppService } from '../../app.service';
import { LayoutService } from '../../layout/layout.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-layout-navbar',
  templateUrl: './layout-navbar.component.html',
  styles: [':host { display: block; }']
})
export class LayoutNavbarComponent {
  isExpanded = false;
  isRTL: boolean;
  username: any

  @Input() sidenavToggle = true;

  @HostBinding('class.layout-navbar') private hostClassMain = true;
  myRawToken: any;

  constructor(private appService: AppService, private layoutService: LayoutService, private helper: JwtHelperService) {
    if (localStorage.getItem('access_token')) {
      this.myRawToken = localStorage.getItem('access_token')
      const decodedToken = helper.decodeToken(this.myRawToken);
      this.username = decodedToken.firstname + " " + decodedToken.lastname
    }
    // console.log(decodedToken)
    this.isRTL = appService.isRTL;

  }

  currentBg() {
    return `bg-${this.appService.layoutNavbarBg}`;
  }

  toggleSidenav() {
    this.layoutService.toggleCollapsed();
  }
}
