import {  Component } from '@angular/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../../vendor/libs/plyr/plyr.scss']
})
export class HomeComponent  {
  
  
 
  
  constructor(private appService: AppService,) {
  
   
    
  }
 
}

